<template>
  <v-dialog persistent  v-model="toggle" width="500">
    <v-card >
      <v-app-bar class="" color="blue" dark dense>
        <div class="dialog-recover-password-titulo-text">
          <v-toolbar-title>Nova Senha</v-toolbar-title>
        </div>
      </v-app-bar>
      <v-form>
        <v-card-text>
          <v-text-field 
            label="Nova senha" 
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
            v-model="user.newPassword"
            height="30px"
            dense
            :error-messages="newPasswordErrors"
            @input="$v.user.newPassword.$touch()" 
            @blur="$v.user.newPassword.$touch()" 
            />
        </v-card-text>
        <v-card-text>
          <v-text-field 
            label="Confirme a nova senha" 
            prepend-icon="mdi-lock"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            v-model="user.confirmPassword"
            height="30px"
            dense
            :error-messages="confirmPasswordErrors"
            @input="$v.user.confirmPassword.$touch()" 
            @blur="$v.user.confirmPassword.$touch()" 
            />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn 
            type="submit" 
            :disabled="btnErrors"
            color="info" 
            @click.prevent="changePassword" 
            class="login-btn" 
            tile 
            :loading="loading"
          >Alterar senha</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

export default {
  name:'NewPassword',
  data: () => ({
    toggle: false,
    token:'',
    user:{
      newPassword: '',
      confirmPassword: '',
    },
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    snackbar: {
      toggle: false,
      type: null,
      msg: null
    },
  }),

  mounted(){
    this.openNewPasswordModal()
  },

  computed: {
    newPasswordErrors () {
      const errors = []
      if (!this.$v.user.newPassword.$dirty) return errors
      !this.$v.user.newPassword.required && errors.push('Campo obrigatório.')
      return errors
    },

    confirmPasswordErrors () {
      const errors = []

      if (!this.$v.user.confirmPassword.$dirty) return errors
      if (this.$v.user.newPassword.$model != this.$v.user.confirmPassword.$model) errors.push('Senhas não sao iguais.')
      !this.$v.user.confirmPassword.required && errors.push('Campo obrigatório.')
      
      return errors
    },

    btnErrors () {
      if (
        this.$v.user.newPassword.$model == this.$v.user.confirmPassword.$model 
        && this.$v.user.newPassword.$model != ''
        && this.$v.user.confirmPassword.$model != ''
      ) return false
      
      return true
    }
  },

  methods: {
    ...mapActions('auth', ['recoverPassword']),

    changePassword () {
      const self = this
      this.loading = true

      if (this.$v.user.$invalid || (this.$v.user.newPassword.$model != this.$v.user.confirmPassword.$model)) { 
        this.$v.user.$touch()
        this.loading = false
        return false 
      }

      self.recoverPassword({token: this.token, password: this.user.newPassword}).then((res) => {
        this.loading = false
        this.toggle = false
        successSnackbar(res.data.message)
        self.$router.replace('/home')
        return true
      }).catch(e => {
        this.loading = false
        errorSnackbar(e.message)
        return false
      })
    },

    openNewPasswordModal () {
      Events.$on('auth::openModalNewPassword', data => {
        this.token = data
        this.toggle = true
      })
    }
  },

  validations () {
    return {
      user:{
        newPassword: {required},
        confirmPassword: {required},
      }
    }
  }
}
</script>

<style lang="scss" scoped src="../../../assets/styles/auth.scss"></style>