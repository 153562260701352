<template>
  <div class="login">
    <div class="login-box">
      <!-- <img src="@/assets/img/logo.png" alt="Logo" class="logo" /> -->
      <v-toolbar-title 
        style="
        font-size: 31px;
        text-align: center;
        width: 100%;
        color: rgb(255, 255, 255);
        font-weight: 100;
        text-transform: uppercase;
        margin-bottom: 1.3rem;
        position: relative;
        z-index: 999999
        ">
        Gestao 
      <v-tooltip v-model="show" right>
        <template v-slot:activator="{ on }">
          <strong @mouseover="show = true" @mouseout="show = false" style="font-weight: 800;"> Única </strong>
        </template>
        <span> v.{{ appVersion }}</span>
      </v-tooltip>

    </v-toolbar-title>
     
      <v-card class="card-login">
        <p class="card-login-texto">Entre para iniciar sua sessão</p>
        <v-form>
          <v-card-text>
            <v-text-field 
              label="Usuário" 
              prepend-icon="mdi-account-circle"
              v-model="user.name"
              height="30px"
              dense
              :error-messages="nameErrors"
              @input="$v.user.name.$touch()"
              @blur="$v.user.name.$touch()" />
            <v-text-field 
              :type="showPassword ? 'text' : 'password'" 
              label="Senha"
              prepend-icon="mdi-lock"
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              height="30px"
              dense
              :error-messages="passwordErrors"
              @input="$v.user.password.$touch()" 
              @blur="$v.user.password.$touch()" />

            <div class="text-right">
              <a class="login-esqueci-senha-btn" @click.prevent="showRecoverPasswordModal">Esqueci a senha</a>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn type="submit" color="primary" @click.prevent="submit" class="login-btn" tile :loading="loading">Entrar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>

    <v-snackbar
      v-model="snackbar.toggle"
      top
      right
      :color="snackbar.type"
    >
      {{ snackbar.msg }}

      <v-btn
        dark
        text
        @click="snackbar.toggle = false"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <recoverPasswordModal/>
    <newPasswordModal/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Events from '../../core/service/events'
import newPasswordModal from './components/modalNewPassword'

export default {
  name: 'Login',
  mixins: [validationMixin],
  components: {
    recoverPasswordModal: () => import ('./components/modalRecoverPassword'),
    newPasswordModal
  },

  data: () => ({
    show: false,
    snackbar: {
      toggle: false,
      type: null,
      msg: null
    },
    loading: false,
    showPassword: false,
    valid: true,
    user: {
      name: '',
      password: '',
    }
  }),
  
  mounted(){
    this.showNewPasswordModal()
  },

  computed: {
    appVersion () {
      return process.env.VUE_APP_VERSION
    },
    nameErrors () {
      const errors = []
      if (!this.$v.user.name.$dirty) return errors
      !this.$v.user.name.required && errors.push('Nome é obrigatório.')
      return errors
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.user.password.$dirty) return errors
      !this.$v.user.password.required && errors.push('Senha é obrigatório.')
      return errors
    },
  },

  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('roles', ['clearAcl']),
    submit () {
      const self = this

      if (this.$v.user.$invalid) { 
        this.$v.user.$touch()
        return false 
      }

      this.loading = true
      self.login(self.user).then(() => {
        self.$router.replace('/home')
      }).catch(e => {
        this.snackbar.toggle = true
        this.snackbar.msg = e
        this.snackbar.type = 'error'
        this.loading = false
        return false
      })
    },

    showRecoverPasswordModal () {
      Events.$emit('auth::openModalRecoverPassword')
    },
    
    showNewPasswordModal(){
      let token = this.$router.currentRoute.query.token

      if (token != null) {
        Events.$emit('auth::openModalNewPassword', token)
      }
    }
  },

  validations () {
    return {
      user: {
        name: { required },
        password: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/styles/auth.scss"></style>